import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { eboekhouden } from 'images'
import { employesUnderline } from 'images/koppelingen'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { eboekhoudenScreenshot } from 'images/koppelingen'

const dummyKlantVerhaal = {
  pageTitle: '“Makkelijk contact en duidelijkheid voor iedereen”',
  pageStreamer: 'Marieke de Vos, Codaisseur',
  useCase: 'Founders',
  person: {
    name: 'Marieke',
    functionTitle: 'HR MANAGER, ebay',
    companyLogo: 'https://via.placeholder.com/129x23',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224/ff48df',
  },
  content: [{
    title: 'Salarisadministratie met Employes & e-Boekhouden.nl',
    description: 'Employes is een salarispakket met een gebruiksvriendelijke interface, speciaal ontwikkeld voor ondernemers. Door de duidelijke stappenplannen en overzichtelijke schermen, hebben wij de manier van gegevens invoeren in ons systeem zo makkelijk gemaakt, dat jijzelf je salarisadministratie foutloos door onze software kan laten doen.'
    }, {
      title: 'Koppel je boekhouding',
      description: 'Na het doen van een verloning, dienen de kosten van je personeel verwerkt te worden in de boekhouding. Dat kun je doen door handmatig een memoriaalboeking te maken in e-Boekhouden.nl. Het overtypen van de journaalposten kan een tijdrovend klusje zijn, maar met Employes niet meer! De koppeling tussen Employes en e-Boekhouden.nl zorgt er namelijk voor dat de journaalposten automatisch aangemaakt worden.'
    }, {
      image: eboekhoudenScreenshot
    }, {
      title: 'Geef je boekhouder gratis toegang tot Employes',
      description: 'Employes werkt samen met verschillende boekhouders. Zij kunnen je helpen bij je boekhouding wanneer je er niet uitkomt. Op de website van e-Boekhouden.nl kun je een overzicht van boekhouders vinden. Je kunt ze gratis toegang geven tot Employes.'
  }]
}

class Informer extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling e-Boekhouden.nl & Employes | Salarisadministratie</title>
            <meta name="description" content="Automatisch je journaalposten importeren in je e-Boekhouden.nl boekhouding met de koppeling van Employes."/>
            <meta itemprop="name" content="Koppeling e-Boekhouden.nl & Employes | Salarisadministratie" />
            <meta itemprop="description" content="Automatisch je journaalposten importeren in je e-Boekhouden.nl boekhouding met de koppeling van Employes."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="koppeling eboekhouden padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel e-Boekhouden.nl aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employesUnderline} alt="logo employes"/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={eboekhouden} alt="afbeelding eboekhouden"/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyKlantVerhaal.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const { title, description } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isImage ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                <p>{ description}</p>
                              </div>
                            ),
                            image: (
                              <img src={section.image}/>
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>

                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Informer.propTypes = {
  data: PropTypes.object
}

export default Informer
